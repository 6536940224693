import React from "react"

import { StaticQuery, graphql, Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo.js"

const site = {}

site.title = process.env.REACT_APP_NAME
// var title = process.env.
const AboutPage = ({ data }) => (
  <StaticQuery
    query={graphql`
      query AboutPageQuery {
        site {
          siteMetadata {
            title
            blogUrl
            appUrl
            getStartedUrl
            twitterUrl
            linkedInUrl
            feedbackUrl
            description
            homeDescription
            homeDescription2
            homeDescription3
            footerDescription
          }
        }
      }
    `}
    render={data => (
      <Layout>
        <SEO title="About" />

        <div className={"page-header"}>
          <h2>{data.site.siteMetadata.title}</h2>
          <p>
            We are helping to build a better, smarter and safer world for
            all.
          </p>
        </div>
        <main role="main">
          {/* <!-- Marketing messaging and featurettes */}
          {/* ================================================== --> */}
          {/* <!-- Wrap the rest of the page in another container to center all the content. --> */}

          <div className="container marketing">
            {/* <!-- Three columns of text below the carousel --> */}
            {/* <div className="row">
          <div className="col-md-12">
            <h2 className="text-black font-weight-normal" />
          </div>
        </div> */}

            {/* <!-- Services section --> */}
            {/* <section id="what-we-do d-none">
          <div className="container-fluid">
            <h2 className="section-title mb-2 h1">What we do</h2>
            <p className="text-center text-muted h5">
              Keep online service providers honest and fight for the user.
            </p>
            <div className="row mt-5">
              <div className="col-xs-12 col-sm-6 col-md-4 col-lg-4 col-xl-4">
                <div className="card">
                  <div className="card-block block-1">
                    <h3 className="card-title">Special title</h3>
                    <p className="card-text">
                      With supporting text below as a natural lead-in to
                      additional content.
                    </p>
                    <a
                      href="javascript:void();"
                      title="Read more"
                      className="read-more"
                    >
                      Read more<i className="fa fa-angle-double-right ml-2" />
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-xs-12 col-sm-6 col-md-4 col-lg-4 col-xl-4">
                <div className="card">
                  <div className="card-block block-2">
                    <h3 className="card-title">Special title</h3>
                    <p className="card-text">
                      With supporting text below as a natural lead-in to
                      additional content.
                    </p>
                    <a
                      href="javascript:void();"
                      title="Read more"
                      className="read-more"
                    >
                      Read more<i className="fa fa-angle-double-right ml-2" />
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-xs-12 col-sm-6 col-md-4 col-lg-4 col-xl-4">
                <div className="card">
                  <div className="card-block block-3">
                    <h3 className="card-title">Special title</h3>
                    <p className="card-text">
                      With supporting text below as a natural lead-in to
                      additional content.
                    </p>
                    <a
                      href="javascript:void();"
                      title="Read more"
                      className="read-more"
                    >
                      Read more<i className="fa fa-angle-double-right ml-2" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xs-12 col-sm-6 col-md-4 col-lg-4 col-xl-4">
                <div className="card">
                  <div className="card-block block-4">
                    <h3 className="card-title">Special title</h3>
                    <p className="card-text">
                      With supporting text below as a natural lead-in to
                      additional content.
                    </p>
                    <a
                      href="javascript:void();"
                      title="Read more"
                      className="read-more"
                    >
                      Read more<i className="fa fa-angle-double-right ml-2" />
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-xs-12 col-sm-6 col-md-4 col-lg-4 col-xl-4">
                <div className="card">
                  <div className="card-block block-5">
                    <h3 className="card-title">Special title</h3>
                    <p className="card-text">
                      With supporting text below as a natural lead-in to
                      additional content.
                    </p>
                    <a
                      href="javascript:void();"
                      title="Read more"
                      className="read-more"
                    >
                      Read more<i className="fa fa-angle-double-right ml-2" />
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-xs-12 col-sm-6 col-md-4 col-lg-4 col-xl-4">
                <div className="card">
                  <div className="card-block block-6">
                    <h3 className="card-title">Special title</h3>
                    <p className="card-text">
                      With supporting text below as a natural lead-in to
                      additional content.
                    </p>
                    <a
                      href="javascript:void();"
                      title="Read more"
                      className="read-more"
                    >
                      Read more<i className="fa fa-angle-double-right ml-2" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}
            {/* <!-- /Services section --> */}
          </div>
          {/* <!-- /.container --> */}

          {/* <MyNetwork /> */}

          {/* <div
          dangerouslySetInnerHTML={
            __html: data.aboutJson.content.childMarkdownRemark.html,
          }
        /> */}

          {/* <ReactReveal
      name="About"
      sections={sections}
      reveal={revealConfig}
    /> */}
          {/* <Affix/> */}
          {/* <section
        className="features features-2"
        style={{ backgroundColor: 'black', height: '50vh' }}
      >
        <div className="container">
          <div className="row">
            <div className="col-xs-12 text-center">
             
            </div>
          </div>
        </div>
      </section> */}

          <div className="jumbotron page-header">
            <div className="container">
              <br />
              <br />
              <h1 className="display-32 text-center">About {site.title}</h1>
              <br />
              <br />
              <div className=" ">
                <div className="about">
                  <div className="topBlock">
                    <div className=" ">
                      <div className="animated fadeIn">
                        <h1 className="topTitle h2 text-center">
                          We are <strong>{site.title}</strong>
                        </h1>
                        <br />
                        <br />
                      </div>
                    </div>
                  </div>
                  <div className="infoBlock">
                    <div className=" ">
                      <div className=" ">
                        <div className="  textGrid">
                          <div className="animated bounceInLeft">
                            <h2 className=" text-center h2">Our Mission</h2>
                            <p>
                              We're on a mission to help customers choose
                              develop solutions impacting millions through high
                              technology services and solutions. In a world with
                              so many digital choices, we want to ensure
                              customers find the right options quickly when it
                              comes to building better, smarter and safer
                              solutions including insights on digital trust,
                              privacy &amp; security practices.
                            </p>
                          </div>
                        </div>

                        <br />
                        <br />
                        <div className="whyBlock">
                          <div className=" ">
                            <div className=" ">
                              <div className="animated bounceInLeft">
                                <h2 className="h2 text-center">
                                  {/* <Link
                                  to="/company/our-story"
                                  className=" btn btn-primary"
                                >
                                  Our Story
                                </Link>{' '} */}
                                  <Link
                                    to="/about/why"
                                    className=" btn btn-primary"
                                  >
                                    Why {site.title}?
                                  </Link>
                                </h2>
                              </div>
                            </div>
                          </div>
                        </div>
                        <br />
                        <br />
                        {/* 
                      <div className=" ">
                        <div className=" textGrid ">
                          <div className="animated bounceInRight">
                            <h2 className=" text-center h2">
                              Choose the right technology to trust
                            </h2> 
                            <p>
                              We've built a platform that helps you find,
                              discover, and compare technologies and their
                              privacy and cyber resilience standards. For each
                              target, you can find aggregated and self reported
                              information of companies, as well as powerful
                              insights about the company's standards, policies,
                              practices, track record, activity, and more. Armed
                              with all this knowledge, you can make more
                              well-informed decisions and choose the right
                              technology solutions and services to tackle any
                              task you may have.
                            </p>
                          </div>
                        </div>
                      </div>

                      <br />
                      <br />
                      <div className="container">
                        <div className="text-center row">
                          <div className="col-md-4   dataItem">
                            <div className="animated fadeIn">
                              <div className="h1">10M+</div>
                              <div className="h4">
                                Sites, Apps &amp; Tech Monitored
                              </div>
                            </div>
                          </div>
                          <div className="col-md-4  dataItem">
                            <div className="animated fadeIn">
                              <div className="h1">716M</div>
                              <div className="h4">Data Points Analyzed</div>
                            </div>
                          </div>
                          <div className="col-md-4   dataItem">
                            <div className="animated fadeIn">
                              <div className="h1">478K</div>
                              <div className="h4">Hours Saved</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <br />
                      <br /> */}
                      </div>
                    </div>
                    <div className="careersBlock">
                      <div className=" ">
                        <div className=" ">
                          <div className="animated bounceInLeft">
                            <h2 className="h2 text-center">
                              Careers at {site.title}
                            </h2>
                            <p>
                              We are currently recruiting our core team. We're a
                              remote-first company, so it doesn't matter where
                              you are in the world, with a few exceptions from time to time. Join us as one of the first
                              employees for a great startup-like culture and
                              energy, generous compensation, a lot of
                              impact and autonomy, and super-interesting
                              challenges. You’ll have influence over{" "}
                              {site.title}'s roadmap, and build solutions that
                              would improve life for millions of customers and
                              vendors.
                            </p>
                            <div className="alignCenter text-center">
                              <a
                                href="/lets-talk/?service=careers"
                                className=" btn btn-dark"
                              >
                                See our open positions
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className=" d-none">
                    <div className=" ">
                      <div className=" ">
                        <div className=" ">
                          <svg
                            id="feedback-icon_svgr__prefix__feedback_icon"
                            width="42.785"
                            height="38.272"
                            data-name="feedback icon"
                            className=" "
                          >
                            <defs>
                              {/* <style>.feedback-icon_svgr__prefix__cls-1{fill:#11ece5}</style> */}
                            </defs>
                            <g
                              id="feedback-icon_svgr__prefix__Group_1549"
                              data-name="Group 1549"
                            >
                              <g
                                id="feedback-icon_svgr__prefix__Group_1548"
                                data-name="Group 1548"
                              >
                                <path
                                  id="feedback-icon_svgr__prefix__Path_942"
                                  d="M40.278 41.54h-5.265V29.507A2.51 2.51 0 0032.506 27H10.278a2.51 2.51 0 00-2.507 2.507v7.521H2.507A2.51 2.51 0 000 39.535V54.91a2.51 2.51 0 002.507 2.507h7.425l3.1 3.1a.836.836 0 001.182 0l1.331-1.331v.239a2.51 2.51 0 002.505 2.505h7.425l3.1 3.1a.836.836 0 001.182 0l3.1-3.1h7.425a2.51 2.51 0 002.507-2.507V44.047a2.51 2.51 0 00-2.511-2.507zM9.443 29.507a.837.837 0 01.836-.836h22.227a.837.837 0 01.836.836v15.376a.837.837 0 01-.836.836h-7.771a.836.836 0 00-.591.245l-2.752 2.752-2.752-2.752a.836.836 0 00-.591-.245h-7.771a.837.837 0 01-.836-.836zm6.93 26.484l-2.752 2.752-2.752-2.752a.836.836 0 00-.591-.245H2.507a.837.837 0 01-.836-.836V39.535a.837.837 0 01.836-.836h5.264v6.184a2.51 2.51 0 002.507 2.507H17.7l3.1 3.1a.836.836 0 001.182 0l3.1-3.1h.49v7.52a.837.837 0 01-.836.836h-7.772a.836.836 0 00-.591.245zm24.741 3.432a.837.837 0 01-.836.836h-7.772a.836.836 0 00-.591.245l-2.752 2.752-2.751-2.756a.836.836 0 00-.591-.245H18.05a.837.837 0 01-.836-.836v-1.91l.1-.1h7.425a2.51 2.51 0 002.507-2.507V47.39h5.265a2.51 2.51 0 002.507-2.507v-1.672h5.265a.837.837 0 01.836.836z"
                                  className="feedback-icon_svgr__prefix__cls-1"
                                  data-name="Path 942"
                                  transform="translate(0 -27)"
                                />
                              </g>
                            </g>
                            <g
                              id="feedback-icon_svgr__prefix__Group_1551"
                              data-name="Group 1551"
                              transform="translate(12.798 4.68)"
                            >
                              <g
                                id="feedback-icon_svgr__prefix__Group_1550"
                                data-name="Group 1550"
                              >
                                <path
                                  id="feedback-icon_svgr__prefix__Path_943"
                                  d="M169.514 83h-15.53a.836.836 0 000 1.671h15.531a.836.836 0 100-1.671z"
                                  className="feedback-icon_svgr__prefix__cls-1"
                                  data-name="Path 943"
                                  transform="translate(-153.148 -83)"
                                />
                              </g>
                            </g>
                            <g
                              id="feedback-icon_svgr__prefix__Group_1553"
                              data-name="Group 1553"
                              transform="translate(28.328 9.359)"
                            >
                              <g
                                id="feedback-icon_svgr__prefix__Group_1552"
                                data-name="Group 1552"
                              >
                                <path
                                  id="feedback-icon_svgr__prefix__Path_944"
                                  d="M340.426 139.245a.835.835 0 10.245.591.842.842 0 00-.245-.591z"
                                  className="feedback-icon_svgr__prefix__cls-1"
                                  data-name="Path 944"
                                  transform="translate(-339 -139)"
                                />
                              </g>
                            </g>
                            <g
                              id="feedback-icon_svgr__prefix__Group_1555"
                              data-name="Group 1555"
                              transform="translate(12.798 9.359)"
                            >
                              <g
                                id="feedback-icon_svgr__prefix__Group_1554"
                                data-name="Group 1554"
                              >
                                <path
                                  id="feedback-icon_svgr__prefix__Path_945"
                                  d="M165.365 139h-11.381a.836.836 0 000 1.671h11.381a.836.836 0 100-1.671z"
                                  className="feedback-icon_svgr__prefix__cls-1"
                                  data-name="Path 945"
                                  transform="translate(-153.148 -139)"
                                />
                              </g>
                            </g>
                            <g
                              id="feedback-icon_svgr__prefix__Group_1557"
                              data-name="Group 1557"
                              transform="translate(16.886 14.039)"
                            >
                              <g
                                id="feedback-icon_svgr__prefix__Group_1556"
                                data-name="Group 1556"
                              >
                                <path
                                  id="feedback-icon_svgr__prefix__Path_946"
                                  d="M214.349 195h-11.442a.836.836 0 000 1.671h11.442a.836.836 0 100-1.671z"
                                  className="feedback-icon_svgr__prefix__cls-1"
                                  data-name="Path 946"
                                  transform="translate(-202.071 -195)"
                                />
                              </g>
                            </g>
                            <g
                              id="feedback-icon_svgr__prefix__Group_1559"
                              data-name="Group 1559"
                              transform="translate(12.785 14.039)"
                            >
                              <g
                                id="feedback-icon_svgr__prefix__Group_1558"
                                data-name="Group 1558"
                              >
                                <path
                                  id="feedback-icon_svgr__prefix__Path_947"
                                  d="M153.906 195h-.07a.836.836 0 100 1.671h.07a.836.836 0 100-1.671z"
                                  className="feedback-icon_svgr__prefix__cls-1"
                                  data-name="Path 947"
                                  transform="translate(-153 -195)"
                                />
                              </g>
                            </g>
                          </svg>
                          Send us feedback
                        </div>
                        <div className=" ">
                          <div className=" ">
                            Got any suggestion, bug report, or question about
                            {site.title}?
                          </div>
                          <form>
                            <div className=" ">
                              <textarea name="message" rows="5" />
                            </div>
                            <div className=" ">
                              <div className=" ">
                                <label className=" ">Name (optional): </label>
                                <input type="text" name="name" value="" />
                              </div>
                              <div className=" ">
                                <label className=" ">Email (optional):</label>
                                <input
                                  type="text"
                                  name="email"
                                  className=" "
                                  value=""
                                />
                              </div>
                            </div>
                            <div tabindex="0" className=" ">
                              <input
                                multiple=""
                                type="file"
                                autocomplete="off"
                                tabindex="-1"
                                // style="display: none;"
                              />
                              <p>
                                Click to select files or drag &amp; drop here
                              </p>
                              <div />
                            </div>
                            <div className=" ">
                              <button
                                type="submit"
                                className="sc-fznzOf bMMhyo"
                              >
                                <div className=" ">Send Feedback</div>
                              </button>
                            </div>
                          </form>
                          <div className=" " />
                        </div>
                        <div className=" ">
                          <span className=" ">
                            <a
                              href="mailto:support@DataPolicyTrust.com?subject=DataPolicyTrust Feedback"
                              target="_blank"
                              rel="nofollow noopener"
                            >
                              Email us
                            </a>
                          </span>
                          <span className=" ">Cancel</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>

        <section className="features features-3">
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-md-offset-1 col-sm-12 text-center">
                <br /> <br />
                <h2>
                  Work, learn & communicate more efficiently through technology
                </h2>
                {/* <h4>
              We help your team save time by pinpointing unclear and potentially
              risky parameters and information where data privacy and personal
              privacy terms are in user agreements and policy documents.
            </h4> */}
                {/* <p>
                    By proactively monitoring, disclosing, annotating and
                    analyzing your own organization and your vendor's, you can
                    ensure a better future for your customers, team and bottom
                    line.
                  </p> */}
                <br /> <br />
                  <p class="h4">
                  UVISION is a technology innovation company headquartered in
                  Reston, VA. We develop new innovative solutions and provide
                  advanced technology solutions, advisory services and R&amp;D
                  services to government agencies, corporations and
                  organizations of all sizes. Leveraging agile R&amp;D processes, a
                  multi-disciplinary collaborative environment, design thinking
                  &amp; results driven execution, UVISION excels in developing
                  concepts into market-focused products and customer-driven
                  solutions. UVISION's core R&amp;D areas include: Artificial
                  Intelligence, Data Science, Data Analytics, Cyber Security,
                  Education and Training Technologies, Modeling and Simulation,
                  Virtual and Augmented Reality, Robotics, and Sensor Systems.
                </p>{" "}
                <br /> <br />
              </div>
            </div>
          </div>
        </section>

        <div className={"container"}>
          <div className={"row"}>
            <div className={"col-md-12"}>
              <div className={"targets"}>
                <div className={"title "}>
                  {/* <h2>Solving Data Transparency</h2> */}
                  <h2>What We Do</h2>
                  <br />
                  <br />
                  <p class="h4">
                    <br />
                    How to maintain compliance and assurances is one of the
                    major problems of companies especially with a growing world
                    of fragmented policies, controls, requirements,
                    technologies, standards and regulations when developing cutting edge technologies and transformation organizations. <br />
                    <br />
                    {data.site.siteMetadata.title} supports organizations, their
leadership teams, technology development teams to drive results.
                    <br />
                    <br />
                  </p>
                </div>
              </div>
            </div>

            <div className={"row"}>
              <div className={"col-md-3"}>
                <div className={"item"}>
                  {/* <img alt={"Information"} src={informationImage}/> */}
                  <h2>Advise</h2>
                  <br />
                  <p>
                    {" "}
                    {data.site.siteMetadata.title} helps organizations advise
                    towards drive results on executive, operational, strategic
                    &amp; technology areas.
                  </p>
                </div>
              </div>

              <div className={"col-md-3"}>
                <div className={"item"}>
                  {/* <img alt={"Event"} src={eventImage}/> */}
                  <h2>Assess</h2>
                  <br />
                  <p>
                    Help assess and audit on variety of topics including
                    executive, operational, strategic and technology areas.
                  </p>
                </div>
              </div>

              <div className={"col-md-3"}>
                <div className={"item"}>
                  {/* <img alt={"Event"} src={eventImage}/> */}
                  <h2>Consult</h2>
                  <br />
                  <p>
                    Help organizations execute key projects, products,
                    innovations & technology solutions.
                  </p>
                </div>
              </div>

              <div className={"col-md-3"}>
                <div className={"item"}>
                  {/* <img alt={"Event"} src={eventImage}/> */}
                  <h2>Develop</h2>
                  <br />
                  <p>
                    Help design, engineer, develop, test, deploy & maintain key
                    projects, product operations & other technology areas.
                  </p>
                </div>
              </div>
              {/*
              <div className={"col-md-3"}>
                <div className={"item"}> 
                  <h2>Manage</h2>
                  <br />
                  <p>
                    Help manage solutions, team on key projects, products,
                    operational, & technology areas.
                  </p>
                </div>
              </div> */}
              <br />
              <br />
            </div>
            {/* 
        <div className={"row"}>
          <div className={"col-md-6"}>
            <div className={"item"}>
              
              <h2>Auditors</h2><br/>
              <p>
                Currently auditors and assessors of various privacy and
                cybersecurity standards store this information in a fragmented
                way and often it's not quick or easy to find this information.
                It's often not clear if an auditor really has certified a tech
                company for a standard or when. {data.site.siteMetadata.title} provides a
                trusted place for auditors and companies to disclose and
                showcase the track record and history of compliance audits.
              </p>
            </div>
          </div>

          <div className={"col-md-6"}>
            <div className={"item"}> 
              <h2>Authorities</h2><br/>
              <p>
                Governments, law enforcement and regulators are often addressing
                and enforcing laws often after a major data breach has occurred
                which affects people and customers often too late after damage
                is done. {data.site.siteMetadata.title} provides useful data since these
                organizations are often lacking insights to effectively monitor
                the trust and accountability of target businesses and
                organizations.
              </p>
            </div>
          </div>
        </div>
         */}
          </div>
        </div>
        <div className={"container"}>
          <div className="row">
            <div className="col-md-12 text-center pb-4">
              {" "}
              <br /> <br />
              <Link to="/about/why" className="btn btn-primary  btn-sm">
                Learn More about Why {data.site.siteMetadata.title} Exists?
              </Link>
              <br /> <br />
            </div>
          </div>
        </div>

        <div className="call-to-action">
          <div className="container">
            <div className="call-to-action__content">
              <h2 className="text-white">Let's talk</h2>
              <p>
                What are your biggest problems? Do you have questions about
                possible solutions? We'd like to help.
              </p>
            </div>
            <div className="button">
              <a href="/lets-talk/">Let's talk</a>
            </div>
          </div>
        </div>
      </Layout>
    )}
  />
)

export default AboutPage
